// extracted by mini-css-extract-plugin
export var locationWrapper = "location-page-layout-module--locationWrapper--V0RsR";
export var navigation = "location-page-layout-module--navigation--CQ1ii";
export var map = "location-page-layout-module--map--9qDQq";
export var mapArea = "location-page-layout-module--mapArea--RJhrp";
export var trap = "location-page-layout-module--trap--4w7Uj";
export var grid = "location-page-layout-module--grid--hEK2R";
export var flags = "location-page-layout-module--flags--Mktzh";
export var general = "location-page-layout-module--general--tmzOD";
export var areaList = "location-page-layout-module--areaList--RHMWB";
export var area = "location-page-layout-module--area--iEkYM";
export var playerStats = "location-page-layout-module--playerStats--sZ9zU";